.c-header-toggler{min-width: 25px;}
.c-sidebar .c-sidebar-nav-title.nav-title-normal{font-size:inherit;text-transform:none;font-weight:inherit;}
.c-sidebar-nav-icon{font-size:1.09375rem !important;}
.c-main{padding-top: 0px !important;}
header{padding-bottom: 20px !important;}

@media (min-width: 992px) {
  .c-sidebar-unfoldable:not(:hover) {z-index:1031;-ms-flex: 0 0 56px;flex: 0 0 56px;}
  .c-sidebar-unfoldable:not(:hover).c-sidebar-fixed {
    z-index: 1031;
    width: 56px;
  }
  .c-sidebar-unfoldable:not(:hover):not(.c-sidebar-right) {
    margin-left: -56px;
  }

  .c-sidebar-unfoldable:not(:hover).c-sidebar-right {
    margin-left: -56px;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-brand-full {
    display: none;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-brand-minimized {
    display: block;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav {
    padding-bottom: 50px;
    overflow: visible;
  }
  .c-sidebar-unfoldable:not(:hover) .c-d-minimized-none,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-divider,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-label,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-title,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-footer,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-form,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-header {
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-minimizer {
    position: fixed;
    bottom: 0;
    width: inherit;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-minimizer::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-minimizer::before, .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-minimizer::before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-item:hover,
  .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    margin-left: -256px;
  }

  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-link, .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle {
    overflow: hidden;
    white-space: nowrap;
    border-left: 0;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-link:hover, .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle:hover {
    width: 312px;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle::after {
    display: none;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link, .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
    width: 256px;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav > .c-sidebar-nav-dropdown {
    position: relative;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items,.c-sidebar-unfoldable:not(:hover) .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown:not(.c-show) > .c-sidebar-nav-dropdown-items {
    display: none;
  }
}

.c-sidebar-unfoldable:not(:hover) .ps {
  overflow: initial !important;
}

@media (min-width: 992px) {
  .c-sidebar-unfoldable {
    z-index: 1031 !important;
  }
  .c-sidebar-unfoldable:hover .c-sidebar-nav-dropdown-items {
    overflow-x: hidden;
  }
  .c-sidebar-unfoldable:hover .c-sidebar-minimizer::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    border: 0;
    box-shadow: 0 3px 4px 0 rgba(var(--elevation-base-color), .14), 0 3px 3px -2px rgba(var(--elevation-base-color), .12), 0 1px 8px 0 rgba(var(--elevation-base-color), .20);
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-sm-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    border: 0;
    box-shadow: 0 3px 4px 0 rgba(var(--elevation-base-color), .14), 0 3px 3px -2px rgba(var(--elevation-base-color), .12), 0 1px 8px 0 rgba(var(--elevation-base-color), .20);
  }
  .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
  .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}

@media all and (min-width: 576px) and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 576px) and (min-width: 992px) and (-ms-high-contrast: active) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-sm-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    box-shadow: 0 3px 4px 0 rgba(60, 75, 100, .14), 0 3px 3px -2px rgba(60, 75, 100, .12), 0 1px 8px 0 rgba(60, 75, 100, .20);
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-md-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    border: 0;
    box-shadow: 0 3px 4px 0 rgba(var(--elevation-base-color), .14), 0 3px 3px -2px rgba(var(--elevation-base-color), .12), 0 1px 8px 0 rgba(var(--elevation-base-color), .20);
  }
  .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
  .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
}
@media all and (min-width: 768px) and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 768px) and (min-width: 992px) and (-ms-high-contrast: active) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-md-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    box-shadow: 0 3px 4px 0 rgba(60, 75, 100, .14), 0 3px 3px -2px rgba(60, 75, 100, .12), 0 1px 8px 0 rgba(60, 75, 100, .20);
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
  .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-lg-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    border: 0;
    box-shadow: 0 3px 4px 0 rgba(var(--elevation-base-color), .14), 0 3px 3px -2px rgba(var(--elevation-base-color), .12), 0 1px 8px 0 rgba(var(--elevation-base-color), .20);
  }
}
@media all and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 992px) and (-ms-high-contrast: active) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    box-shadow: 0 3px 4px 0 rgba(60, 75, 100, .14), 0 3px 3px -2px rgba(60, 75, 100, .12), 0 1px 8px 0 rgba(60, 75, 100, .20);
  }
}
@media all and (min-width: 992px) and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 992px) and (min-width: 992px) and (-ms-high-contrast: active) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-lg-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    box-shadow: 0 3px 4px 0 rgba(60, 75, 100, .14), 0 3px 3px -2px rgba(60, 75, 100, .12), 0 1px 8px 0 rgba(60, 75, 100, .20);
  }
}
@media (min-width: 1200px) and (min-width: 992px) {
  .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }

  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-xl-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    border: 0;
    box-shadow: 0 3px 4px 0 rgba(var(--elevation-base-color), .14), 0 3px 3px -2px rgba(var(--elevation-base-color), .12), 0 1px 8px 0 rgba(var(--elevation-base-color), .20);
  }
  .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}
@media all and (min-width: 1200px) and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 1200px) and (min-width: 992px) and (-ms-high-contrast: active) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-xl-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    box-shadow: 0 3px 4px 0 rgba(60, 75, 100, .14), 0 3px 3px -2px rgba(60, 75, 100, .12), 0 1px 8px 0 rgba(60, 75, 100, .20);
  }
}
@media (min-width: 1400px) and (min-width: 992px) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-xxl-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    border: 0;
    box-shadow: 0 3px 4px 0 rgba(var(--elevation-base-color), .14), 0 3px 3px -2px rgba(var(--elevation-base-color), .12), 0 1px 8px 0 rgba(var(--elevation-base-color), .20);
  }
  .c-sidebar.c-sidebar-xxl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
  .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}

@media all and (min-width: 1400px) and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 1400px) and (min-width: 992px) and (-ms-high-contrast: active) {
  .c-app:not(.c-legacy-theme) .c-sidebar.c-sidebar-xxl-show.c-sidebar-unfoldable, .c-app:not(.c-legacy-theme)
  .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    box-shadow: 0 3px 4px 0 rgba(60, 75, 100, .14), 0 3px 3px -2px rgba(60, 75, 100, .12), 0 1px 8px 0 rgba(60, 75, 100, .20);
  }
}

.clickable{
	cursor: pointer;
}

#loadingOverlay{
	position: absolute;
    height: 200%;
    width: 100%;
	background-color:rgba(255,255,255,0.75);
	top:-100px;
	z-index:99999;
	text-align:center;
	margin: 0 auto;
}
#loadingOverlay > span{
	display: block;
	font-size:36px;
	top: 50%;
	margin: 0 auto;
	position: fixed;
	width: 100%;
}

.bg-dark-gradient{
	background-image: linear-gradient(to bottom, $dark, #737373);
}

#dateTimeSection div{transition: $layout-transition-speed;}

.c-sidebar-unfoldable:not(:hover) #dateTimeSection{position:fixed;bottom:40px;width:inherit;}
.c-sidebar-unfoldable:not(:hover) #dateTimeSection .welcomeTime{font-size:inherit;}
.c-sidebar-unfoldable:not(:hover) #dateTimeSection .welcomeDate,.c-sidebar-unfoldable:not(:hover) #dateTimeSection .welcomeTimeZone{height:0;padding:0;margin:0;visibility:hidden;opacity:0;}

.c-switch-success.c-switch-active .c-switch-slider{background-color:$danger;}

.btn > .fa{margin-right:.5rem;}

.form-group.is-mandatory > label::after {
    position: relative;
    top: 1px;
    display: inline-block;
    color: $danger;
    padding-left: 0.3rem;
    -moz-osx-font-smoothing: grayscale;
    content: "*";
}
.nav-item.has-error > a::before,
.form-group.has-error > label::before,
.accordion-header.has-error > h2 > .accordion-button::before {
    position: relative;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f071";
    color: $warning;
    padding-right: 0.25rem;
}
.has-error label,.has-error .help-block{color: $danger;}
.has-error .select2-container--default .select2-selection--single, .has-error .select2-container--default .select2-selection--multiple {
    border-color: $danger;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.has-error .input-group-text {
    color: $danger;
    background-color: #f2dede;
    border-color:$danger;
    border-right-color: rgb(169, 68, 66);
}
ul.sortable {
    min-height: 10px;
    list-style-type: none;
}
ul.sortable li {
    border: 1px solid #cccccc;
    display: block;
}

.cal-addButton > .btn.btn-context {
    margin-bottom: 0px;
    padding: 5px 8px;
    font-size: 1rem;
}
.cal-addButton > .btn-context.btn-success {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.btn.btn-context{
	font-size: $h1-font-size;
	border: 2px solid $white;
	margin-bottom: -25px;
        padding: 5px 10px;
}
.btn.btn-context > .fa, .btn.btn-smalladd > .fa{margin-right:inherit;}

.btn-context.btn-success {
    color: $white;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
}

.btn-smalladd {
	border: 2px solid $white;
        padding: 5px 7px;
        font-size: 10px;
}

#EnodiaContentWrapper {
    margin: 0 !important;
}

.accborder {
    border: 1px solid #cecece;
    border-radius: 3px;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: .6rem 1.25rem;
    font-size: 1rem;
    color: $light;
    text-align: left;
    background-color: $dark;
    border: 1px solid rgba(0,0,21,.125);
    border-radius: 3px;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.accordion-button:focus {
    z-index: 3;
    outline: 0;
/*    box-shadow: 0 0 0 .25rem rgba(50,31,219,.25); */
}
.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 1%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
    background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(180deg);
}

.popover-vcard{cursor:help;}

.card-object{position:absolute;top:-$card-border-width;font-weight:bold;}
.card-details-close{position:absolute;bottom:-$card-border-width;right:$card-spacer-x;font-weight:bold;}
.card-state {
    text-align: right;
}

.button-toggle-filter-panel .toggle-icon {
	transform: rotate(0deg);
}
.button-toggle-filter-panel.show .toggle-icon {
	transform: rotate(180deg);
}
#calendarRow{
	display: flex;
}
.toggleCalendarRow.toggle-icon {
	transform: rotate(0deg);
	position: absolute;
	right: 3px;
	top: 3px;
}
.toggleCalendarRow.toggle-icon.show {
	transform: rotate(180deg);
}
.cardsInfo{margin-top:-50px;padding-right:40px;}

.commentbubble {
    position:relative;
    display: inline-block;
    margin-top: 8px;
}
.commentbubble .badge {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 50%;
	margin-left: 0;
}
.commentTime {
    font-style: italic;
    font-size: 11px;

}
.commentList ul {
    list-style: none;
    padding-left: 15px;
}
.commentList ul li {
    margin-top: 10px
}
ul.commentList > li {
    margin-bottom: 20px
}
.form-control.newCommentStyle {
    height: 80px;
    overflow-y: scroll;
}
.avatar_wrapper .badge{
	position: absolute;
	bottom: 0;
	right: 0;
	font-size:$font-size-base;
}

.usersClock {
    font-family: $headings-font-family;
}

.commentList{list-style:none;}

.select2-container {width: 100% !important;}
.select2-container .select2-selection--multiple { min-height: auto !important; }
.select2-container--default .select2-selection--multiple { padding-bottom: 3px !important; }
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple { border: 1px solid #d8dbe0; }

.errortext { font-family: "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; color: $danger; font-size: 0.85em; }

.daterangepicker.show-ranges.ltr .drp-calendar.left { border-left: 0 !important; }
.daterangepicker td.active { border: 0 !important; }

.btn { line-height: 1.2 !important;}

.defaultAvatar { overflow: hidden; text-align: center; font-weight: 700; display: flex; align-items: center; justify-content: center; }
.defaultAvatarSelect { overflow: hidden; text-align: center; font-weight: 700; display: inline-block; vertical-align: middle; }
.defaultAvatarSelect .circle { line-height: 1.8em; }
.enodia-card label{
	font-weight: bold;
	margin-bottom: unset;
}

.enodia-card {
    transition: box-shadow 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    color: $white;
}
.enodia-card.card,
.enodia-card .card-header,
.enodia-card-breadcrumb.card,
.enodia-card-breadcrumb.card .card-header{
    color: $white;
    background-color: $dark !important;
    border-color: rgba(0, 0, 0, 0.125)
}

.enodia-card-breadcrumb.card a,
.enodia-card-breadcrumb .card-title-container,
.enodia-card-breadcrumb.card .card-body{
    color: $white;
}

.enodia-card-list .enodia-card, .enodia-card-list {
    width: 100%;
}

.enodia-card-list .enodia-card {
    background-color: #272727;
}

.enodia-card-columns .enodia-card .newline {
    display: inline;
}
.enodia-card-columns .enodia-card .toggleableDetailsField {
	cursor: n-resize;
	display: inline-block;
	word-break: break-word;
}
.enodia-card-columns .enodia-card .toggleableDetailsField.notesdetails {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block !important;
    cursor: s-resize;
}

.enodia-card-list .enodia-card .newline {
    padding-left: 5px;
}

.enodia-card .detailline label {
    background-color: #534a4a80;
    padding: 2px 5px;
    border-radius: 2px;
}

.enodia-card-list .enodia-card .col-xl-5, .enodia-card-list .enodia-card .col-xl-3, .enodia-card-list .enodia-card .col-xl-4 {
    position: inherit !important;
}

.enodia-card-list .enodia-card .details {
    background-image: linear-gradient(to bottom, #272727, #737373);
}

.enodia-card-list .enodia-card:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.enodia-card-list .enodia-card:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.enodia-card-list .enodia-card:nth-child(even) {
    background-color: #403939;
}

.enodia-card-list .enodia-card:nth-child(even) .details {
    background-image: linear-gradient(to bottom, #403939, #737373);
}

.enodia-card-list .card-title-container {
    min-width: 35%;
}

.enodia-card:hover {
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    transform: scale(1.02);
    cursor: pointer;
    z-index: 800;
    position: relative;
}
.enodia-card.draggableCard:hover {
	cursor: move;
}

.enodia-card-list .enodia-card:hover {
    transform: scale(1.01);
}

.enodia-card-breadcrumb {
    width: 28%;
    margin-right: 3%;
    min-width: 350px;
}

.enodia-card-list .enodia-card .statebadge button {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.enodia-card-list .enodia-card .notefield {
    max-height: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.enodia-card-columns .enodia-card .statebadge button, .enodia-card-breadcrumb .statebadge > span {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



/*
.enodia-card-breadcrumb:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-left-color: $dark;
    border-width: 20px;
    margin-top: -20px;
}
*/

.enodia-card-columns {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    width: 100%;
}

a.homepageLink,
a.card-link {
    color: $warning;
}

.enodia-card-breadcrumb .card-title a {
    color: $light;
}

.add-entity {
    font-family: $headings-font-family;
    font-size: 1.2em;
}

.add-entity .entity-count {
    font-size: 1.2em;
    background: #11a6dc;
    color: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.add-entity a .entity-count:hover {
    cursor: pointer;
    text-decoration: none;
    background: $danger;
}

.add-entity a:hover {
    text-decoration: none;
}

.enodia-card-columns .enodia-card {
    width: 32%;
    margin-bottom: 2%;
}

//.event-card-eventtype span, .channel-card-channeltype span {
//    background: #0083ca;
//    padding: 0px 4px;
//    border: 1px solid #eef2f4;
//    border-radius: 5px;
//    margin: 3px 0;
//    display: inline-block;
//}

.badgesize .badge, .badgesize.badge {
    font-size: 1.2em;
}

/* Re-order items into 3 rows
 uWe: We use inline order style computed by php
.enodia-card-columns .enodia-card:nth-child(3n+1) { order: 1; }
.enodia-card-columns .enodia-card:nth-child(3n+2) { order: 2; }
.enodia-card-columns .enodia-card:nth-child(3n)   { order: 3; }
*/
/* Force new columns */
.enodia-card-columns::before,
.enodia-card-columns::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
}
.card-coowner{
	margin-left: -.25rem;
}

.dropdown-menu .card-coowner:first-child {
    margin-left: .25rem;
}

.dropdown-menu .card-coowner:last-child {
    margin-right: .25rem;
}

.c-sidebar .c-sidebar-brand {
    background: none;
}
.select-avatar {
    display: inline-block;
}

.notifybg {
    background: #11a6dc;
    border-radius: 50%;
    padding: 6px 7px;
}

.notifybg .badge{
	position: absolute;
	top: -15%;
	left: 66%;
}

.dutylist button {
    white-space: nowrap;
}

.calendarCardEntryList {
    font-family: $headings-font-family;
}

.dateSelector {
    font-family: $headings-font-family;
    padding-right: 10px;
}


.start-before {
    border-left: 0 !important;
    border-radius: 0 0.25rem 0.25rem 0 !important;
    position: relative;
    margin-left: 10px;
}

 .start-before:before {
    background: linear-gradient(-137deg, #272727 6px, transparent 0) 0 5px, linear-gradient(320deg, #272727 5px, #ebedef 0) 0 5px;
    background-color: #272727;
    background-position: left bottom;
    background-repeat: repeat-y;
    background-size: 10px 10px;
    height: calc(100% + 1px);
    width: 10px;
    content:" ";
    position: absolute;
    left: -8px;
    top: 0px;
}

.end-after {
    border-right: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
    position: relative;
    margin-right: 10px;
}

 .end-after:after {
    background: linear-gradient(-137deg, #ebedef 6px, transparent 0) 0 5px, linear-gradient(320deg, #ebedef 5px, #272727 0) 0 5px;
    background-color: #272727;
    background-position: left bottom;
    background-repeat: repeat-y;
    background-size: 10px 10px;
    height: calc(100% + 1px);
    width: 10px;
    content:" ";
    position: absolute;
    right: -8px;
    top: -1px;
}

.days-row {
    position: sticky;
    top: 130px;
    z-index: 999;
    background: #ebedef;
}

.card.calendarColumn {
    border-radius: 0;
}

.card.calendarColumn:not(:first-child):not(:last-child) {
    border-left: 0 !important;
}

.card.weekColumn:nth-child(6) {
    border-right: 0 !important;
}

.calendarCardDate a {
    color: #be1522;
}

.datelink:hover {
    cursor: pointer;
}

.form-group > label {
    font-size: 110%;
}

#sectionDivisions.sectionWrapper, #sectionPriority.sectionWrapper, #sectionFurtherMarkets.sectionWrapper, #sectionMarkets.sectionWrapper, #sectionFurtherMarketIds.sectionWrapper, #sectionExternalFileReference.sectionWrapper, #sectionFutureChannel.sectionWrapper {
    border: 2px dotted #cecece;
    padding: 10px;
    margin-bottom: 1rem;
    border-radius: 6px;
}

.rolemenu:hover, .roledisplay:hover {
    cursor: pointer;
}

.rolemenu a {
    color: black;
}

.rolemenu li.active a {
    color: white;
}

.rolemenu li.dropdown-item.active {
    background-color: #be1522;
}

.col-xs-4 .checkbox {
    padding-right: 15px;
    padding-left: 15px;
}

.checkbox {
    white-space: normal;
}

.orderselects .select2-container--default {
    min-width: 110px;
}

#log {
    display: none;
}

.plupload_header {
    display: none;
}

.plupload_container {
    min-height: 240px !important;
}

.plupload_content {
    top: 0 !important;
    bottom: 0 !important;
}

.c-sidebar.c-sidebar-overlaid {
    overflow-y: scroll;
    overflow-x: hidden;
}

.notificationRow, .taskWidgetRow, .todayWidgetRow {
    margin-bottom: 20px;
}

.notificationRow:last-child, .taskWidgetRow:last-child, .todayWidgetRow:last-child {
    margin-bottom: 0px;
}

.wd-taskicon span, .wd-todayicon span {
    font-size: 1.5rem;
}

.wd-todaydate {
    font-family: $headings-font-family;
    font-size: 0.9rem;
}

.yearColumn{
	width: calc(100% / 12);
}
@for $i from 1 through 12 {
	.yearColumn[data-length='#{$i}']{
		width: calc(100% / 12 * #{$i});
	}
	.yearColumn[data-offset='#{$i}']{
		margin-left: calc(100% / 12 * #{$i});
	}
}
@for $i from 1 through 6 {
	.monthColumn-#{$i}{
		width: calc(100% / #{$i});
	}
	@for $ii from 1 through 6 {
		.monthColumn-#{$i}[data-length='#{$ii}']{
			width: calc(100% / #{$i} * #{$ii});
		}
		.monthColumn-#{$i}[data-offset='#{$ii}']{
			margin-left: calc(100% / #{$i} * #{$ii});
		}
	}
}
.weekColumn{
	width: calc(100% / 7);
}
@for $i from 1 through 7 {
	.weekColumn[data-length='#{$i}']{
		width: calc(100% / 7 * #{$i});
	}
	.weekColumn[data-offset='+#{$i}']{
		margin-left: calc(100% / 7 * #{$i});
	}
}
.dayColumn{
	width: calc(100% / 6) ;
}
@for $i from 1 through 7 {
	.dayColumn[data-length='#{$i}']{
		width: calc(100% / 6 * #{$i});
	}
	.dayColumn[data-offset='#{$i}']{
		margin-left: calc(100% / 6 * #{$i});
	}
}

.dayColumn-office{
    width: calc(100% / 4) ;
}
@for $i from 1 through 7 {
    .dayColumn-office[data-length='#{$i}']{
        width: calc(100% / 4 * #{$i});
    }
    .dayColumn-office[data-offset='#{$i}']{
        margin-left: calc(100% / 4 * #{$i});
    }
}

.daypartColumn{
    width: calc(100% / 8) ;
}
@for $i from 1 through 9 {
    .daypartColumn[data-length='#{$i}']{
        width: calc(100% / 8 * #{$i});
    }
    .daypartColumn[data-offset='#{$i}']{
        margin-left: calc(100% / 8 * #{$i});
    }
}

@media (max-width:1100px) {
  .enodia-card-columns .enodia-card {
      width: 100%;
      diplay: block !important;
  }
  .enodia-card-columns {
      display: block !important;
      height: auto !important;
      padding: 0 !important;
  }
  #EnodiaContentWrapper .row {
      margin: 0;
  }

  #EnodiaContentWrapper .enodia-card .row {
      margin-right: -15px;
      margin-left: -15px;
  }
}

@media only screen and (max-width: 1550px) and (min-width: 1100px) {

}

#button-save-filter > span, #button-del-filter > span {
    position: relative;
    top: 0.2em;
    font-size: 1.4rem;
}
.dropdown-menu.state-select-dropdown{
	padding: 0;
}
.state-select-dropdown  label{
	margin-bottom: 0.05rem;
	padding-left: .4rem;
}

#selectAll + label span{
  	position:absolute;
  	top:0;
}
#selectAll + label span.select,
#selectAll.is_active + label span.deselect
{
	visibility: visible;
  	opacity: 1;
  	transition: visibility 0.7s, opacity 0.5s linear;
}
#selectAll + label span.deselect,
#selectAll.is_active + label span.select{
	visibility: hidden;
  	opacity: 0;
  	transition: visibility 0.7s, opacity 0.5s linear;
}


$duration: 30s;
$repeats: 2;

/*****************************
*	horizontal news ticker
******************************/

.newsboard-ticker-wrapper{
	display: flex;
	position: relative;
	overflow: hidden;
	width: 100%;
	background-color: rgba(#000, 0.9);
	-moz-animation: hide-ticker 1s ease-in $duration*$repeats forwards;
  	-webkit-animation: hide-ticker 1s ease-in $duration*$repeats forwards;
  	-o-animation: hide-ticker 1s ease-in $duration*$repeats forwards;
  	animation: hide-ticker 1s ease-in $duration*$repeats forwards;
  	-webkit-animation-fill-mode: forwards;
  	animation-fill-mode: forwards;
}



.newsboard-ticker{
	display: flex;
	margin:0;
	padding: 0;
	padding-left: 90%;
	z-index: 999;

	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-name: tic;
	animation-duration: $duration;

	li{
		display: flex;
		width: 100%;
		align-items: center;
		white-space: nowrap;
		padding-left: 20px;
		span{
			color: white;
			font-size: 1rem;
		}
	}
}
.news-ticker-h:hover {
	animation-play-state: paused;
}



@keyframes tic {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
@-webkit-keyframes tic {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
@keyframes hide-ticker {
    to {
        height:0;
        visibility:hidden;
    }
}
@-webkit-keyframes hide-ticker {
    to {
        height:0;
        visibility:hidden;
    }
}

#sofwareTab.nav-tabs{
	margin-bottom: -16px;
 	.nav-link{
		background:none;
	}
	.nav-link.active,.nav-item.show .nav-link{
		color: $danger;
	}
}

#openProfile{font-size:20rem;}

#enodiaModal .dropzone .dz-message .dz-button{
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  user-select: none;
  color: #272727;
  background-color: transparent;
  border: $btn-border-width solid #272727;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover() {
    color: #ffffff;
    background-color: #272727;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

    &:active,
    &.active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }

  i,
  .c-icon {
    height: $btn-font-size;
    margin: ((($font-size-base * $line-height-base) - $font-size-base) / 2) 0;
  }
}

.font-strikethrough{
	 text-decoration: line-through;
}

.card-header {
	padding: 0.4rem 1.25rem;

}


.enodia-tour .shepherd-arrow::before{
	background-color: #e2e2e2;
}
.enodia-tour.shepherd-element{
	background: transparent;
	box-shadow: none;
	max-width: 500px;
}
.enodia-tour .shepherd-text{
	background-color: #e2e2e2;
	border-radius: 5px;
	box-shadow: 0 1px 4px rgba(0,0,0,.2);
}
.enodia-tour .shepherd-footer{
	border:none;
	padding-top: 5px;
	padding-right: 0;
	color: #ffffff;
}
.enodia-tour .shepherd-cancel-button,
.enodia-tour .shepherd-cancel-button:not(:disabled):hover {
	background: transparent;
	color: #ffffff;
}
.enodia-tour .shepherd-next-button,
.enodia-tour .shepherd-next-button:not(:disabled):hover,
.enodia-tour .shepherd-previous-button,
.enodia-tour .shepherd-previous-button:not(:disabled):hover{
	background: #5d6979;
	font-weight: bold;
}
// disable all elements if tour is running
body.shepherd-disabled a,
body.shepherd-disabled button:not(.shepherd-button),
body.shepherd-disabled input,
body.shepherd-disabled label,
body.shepherd-disabled .btn,
body.shepherd-disabled .select2-container{
    pointer-events: none;
}

#toggle-filter-panel{
    color: $danger;
}
#toggle-filter-panel.show{
    color: #000;
}

.calendar-card.card{
    background-color: $dark;
    color: $white;
    border-color: $dark;
}

.calendar-card-menu{
    position: absolute;
    right: -9px;
    top: -8px;
    z-index: 1;
    display: none;
}

.btn.disabled{
    cursor: not-allowed;
}
button:disabled,
button.disabled{
    cursor: not-allowed !important;
    pointer-events: visible !important;
}

.dropdown-menu{
    cursor: default;
}

.cutoff{
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}

.channelTypeGroupMarker{
    height: .75rem;
    width: .75rem;
    border-radius: 50%;
    display: inline-block;
}
#selectAllChannels + label .deselect{
    display: none;
}
#selectAllChannels + label .select{
    display: inline;
}
#selectAllChannels.is_active + label .deselect{
    display: inline;
}
#selectAllChannels.is_active + label .select{
    display: none;
}

.enodia-card.dpa-card,
.enodia-card.dpa-card .card-header,
.calendar-card.dpa-card,{
    background-color: rgba(175,214,153,.48) !important;
}
