@font-face{font-family:'Viga';src:url('../../Fonts/Viga-Regular.woff2') format('woff2'),url('../../Fonts/Viga-Regular.woff') format('woff'),url('../../Fonts/Viga-Regular.ttf') format('truetype');font-weight: normal;font-style: normal;}
@font-face{font-family:'Roboto';src: url('../../Fonts/roboto-v27-latin-300.woff2') format('woff2'),url('../../Fonts/roboto-v27-latin-300.woff') format('woff'),url('../../Fonts/roboto-v27-latin-300.ttf') format('truetype'),url('../../Fonts/roboto-v27-latin-300.svg#Roboto') format('svg');font-style: normal;font-weight: 300;}
@font-face{font-family:'Roboto';src: url('../../Fonts/roboto-v27-latin-regular.woff2') format('woff2'),url('../../Fonts/roboto-v27-latin-regular.woff') format('woff'),url('../../Fonts/roboto-v27-latin-regular.ttf') format('truetype'),url('../../Fonts/roboto-v27-latin-regular.svg#Roboto') format('svg');font-style: normal;font-weight: 400;}
@font-face{font-family:'Roboto';src: url('../../Fonts/roboto-v27-latin-700.woff2') format('woff2'),url('../../Fonts/roboto-v27-latin-700.woff') format('woff'),url('../../Fonts/roboto-v27-latin-700.ttf') format('truetype'),url('../../Fonts/roboto-v27-latin-700.svg#Roboto') format('svg');font-style: normal;font-weight: 700;}

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rtl:     false;
$enable-shadows: true;

// Variable overrides

// Body
//
// Settings for the `<body>` element.
$body-color:  #000;


// Typography
//
// Font, line-height, and color for body text, headings, and more.
$headings-font-family:  Viga, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: Roboto, Viga, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base:  .75rem; // 12px

$h1-font-size:  1.625rem;

// Colors
$danger-dark:  #d93737 !default;
$danger:       #be1522;
$danger-50:    #f2a9a9 !default;
$danger-25:    #f9d4d4 !default;

$info-dark:  #2982cc !default;
$info:       #11a6dc;
$info-50:    #80c6ff !default;
$info-25:    #c0e6ff !default;

$secondary-bg:       #5e5e5e;

$success-dark:      #1b9e3e !default;
$success:           #5cb85c;
$success-50:        #96dbad !default;
$success-25:        #cbedd6 !default;
$success-color:     #fff;

$warning-dark:  #f6960b !default;
$warning:       #f9b115 !default;
$warning-50:    #fcd88a !default;
$warning-25:    #feecc5 !default;

$dark:   #272727;
$white:  #fff;



$header-light-bg:  #ebedef;
$header-light-border:  0;
$header-toggler-font-size: .5rem;
$header-toggler-bg:  #000;
$header-toggler-border-radius:  0 0 .25rem 0;
$header-light-color: #fff;



$sidebar-dark-bg:  $dark;
$sidebar-dark-nav-link-active-bg:  $danger;
$sidebar-dark-nav-link-hover-bg: $info;
$sidebar-dark-nav-title-color: #fff;
$sidebar-dark-nav-link-color: #fff;
$sidebar-dark-nav-link-icon-color: #fff;



$input-line-height-sm: 1.7 !default;
$input-line-height: 1.2 !default;