@import "settings/custom";

$body-color:  #fff;

$link-color: 	$danger;


$input-bg: linear-gradient(to bottom right, #272727, #737373);


$input-color:         #fff;
$input-group-addon-bg: #7f7f7f;

@import "./node_modules/@coreui/coreui/scss/bootstrap";
@import "./node_modules/@coreui/coreui/scss/root";
@import "./node_modules/@coreui/coreui/scss/app";
@import "./node_modules/@coreui/coreui/scss/reboot";

// Vendors
@import "./node_modules/@coreui/coreui/scss/vendors";

// Components
@import "./node_modules/@coreui/coreui/scss/alert";
@import "./node_modules/@coreui/coreui/scss/buttons";
@import "./node_modules/@coreui/coreui/scss/close";
@import "./node_modules/@coreui/coreui/scss/forms";
@import "./node_modules/@coreui/coreui/scss/grid";
@import "./node_modules/@coreui/coreui/scss/icon";
@import "./node_modules/@coreui/coreui/scss/input-group";
@import "./node_modules/@coreui/coreui/scss/modal";
@import "./node_modules/@coreui/coreui/scss/spinners";
@import "./node_modules/@coreui/coreui/scss/tooltip";
@import "./node_modules/@coreui/coreui/scss/transitions";

// Layout Options
@import "./node_modules/@coreui/coreui/scss/layouts";

// Utility classes
@import "./node_modules/@coreui/coreui/scss/utilities";

// Custom Properties support for Internet Explorer
@import "./node_modules/@coreui/coreui/scss/ie-custom-properties";

@import "./node_modules/@coreui/coreui/scss/print";

@import "custom.scss";

/* Login Form */
#backgroundPic{position:fixed;top:0px;left:0px;width:100%;height:100%;background:linear-gradient(270deg,#5e5e5e 0%,#3e3e3e 50%,#5e5e5e 100%);}
#loginBackgroundImage{width:100%;height:100%;object-fit:cover;opacity:0.8;}
#login-wrapper{ font-size: .75rem; /* 12px */ }
#login-wrapper a{ color:#f9b115; }
#login-modal{position:relative;}
#btn-login{position:absolute;bottom:0;left:0;}
#langChooser{position:absolute;top:.75rem;right:.75rem;}
#langChooser a{padding-right:.2rem;}
#langChooser a:last-child{padding-right:0;}


body{background-image: linear-gradient(to top left, #272727, #737373);}

#login-modal,.form-control{background-image: linear-gradient(to bottom right, #272727, #737373);}




